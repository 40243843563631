/* eslint-disable no-duplicate-imports */
import * as React from 'react';
import { ILinkData } from './logo';

export interface INotesProps {
    link?: ILinkData;
    className?: string;
}

/**
 *
 * Notes component.
 * @extends {React.PureComponent<INotesConfig>}
 */
export class Notes extends React.PureComponent<INotesProps> {
    public render(): JSX.Element {
        return <div>{this._renderNotesLink(this.props)}</div>;
    }

    private _renderNotesLink(config: INotesProps): JSX.Element {
        if (config.link && config.link.linkUrl.destinationUrl) {
            return (
                <a
                    href={config.link.linkUrl.destinationUrl}
                    aria-label={config.link.ariaLabel}
                    target={config.link.openInNewTab ? '_blank' : undefined}
                    rel='noopener noreferrer'
                    className={config.className}
                ></a>
            );
        }

        return (
            <div className={config.className}>
                <p></p>
            </div>
        );
    }
}

export default Notes;
